import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import { Container, DangerouslySetInnerHtml } from 'layout';

import SaveWaterHeader from 'components/SaveWaterHeader';
import SaveWaterPageCard from './SaveWaterPageCard/SaveWaterPageCard';
import { ISaveWaterBannerProps } from './models';

import './SaveWaterBanner.scss';

const SaveWaterBanner: FC<ISaveWaterBannerProps> = ({ banner }) => {
  const { hexTitle, title, image, pageCards, header } = banner[0].properties;

  return (
    <div data-testid="SaveWaterBanner" className="save-water-banner__wrapper">
      <GatsbyImage
        image={image[0].properties.image}
        alt={image[0].properties.imageAlt}
        className="save-water-banner__image"
      />
      <SaveWaterHeader header={header} />
      <Container className="save-water-banner__container">
        <div className="save-water-banner__text">
          <div className="save-water-banner__hex-title">{hexTitle}</div>
          <DangerouslySetInnerHtml html={title} className="save-water-banner__title" />
        </div>
        <div className="save-water-banner__page-cards">
          {pageCards.map((el) => (
            <SaveWaterPageCard data={el} key={el.properties.title} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSaveWaterBanner on TSaveWaterBanner {
    properties {
      header {
        ...FragmentSaveWaterHeader
      }
      hexTitle
      title
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      pageCards {
        ...FragmentSaveWaterPageCard
      }
    }
  }
`;

export default SaveWaterBanner;
