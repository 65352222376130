export const cookieName = 'PLEDGE_IS_TAKEN';
export const apiConfig = {
  usa: {
    baseUrl: 'https://hgy8owkflf.execute-api.us-east-1.amazonaws.com/prod',
    getUrl: '/statistics',
    postUrl: '/items',
    factor: 2995,
    authorization:
      'eyJhbGciOiJIUzI1NiJ9.ewoJInVybCI6Ind3dy5maW5pc2hkaXNod2FzaGluZy5jb20iCn0._GYDPMC-qaOoRkjqoCaqyzkAmf1s7QM6JI6zO_mSXIs',
    origin: 'https://www.finishdishwashing.com',
    payload: {
      pledge_sum: 2995,
    },
  },
  canada: {
    baseUrl:
      'https://cfwg4t7tvl.execute-api.eu-central-1.amazonaws.com/default/posejdon-counter-api',
    getUrl: '?country=canada',
    postUrl: '',
    factor: 7000,
    origin: 'https://www.finishdishwashing.ca',
    authorization: null,
    payload: {
      apiKey: 'dA4s6Us76KxaUyG99wcZ1rFiT2gvRWro',
      count: 1,
      country: 'canada',
    },
  },
};
