import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';

import { ISaveWaterHeaderProps } from './models';
import './SaveWaterHeader.scss';

const SaveWaterHeader: FC<ISaveWaterHeaderProps> = ({ header }) => {
  const { logos } = header[0].properties;

  return (
    <div className="save-water-header">
      {logos.map(({ properties: { imageAlt, image: logoImage } }) => (
        <GatsbyImage
          key={imageAlt}
          image={logoImage}
          alt={imageAlt}
          className="save-water-header__logo-image"
        />
      ))}
    </div>
  );
};

export const query = graphql`
  fragment FragmentSaveWaterHeader on TSaveWaterHeader {
    properties {
      logos {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default SaveWaterHeader;
