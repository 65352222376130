import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { useCountUp } from 'react-countup';
import axios from 'axios';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Button from 'common/Button';

import { getCookies, setCookies } from 'utils/cookies';

import { apiConfig, cookieName } from './constants';
import { IPledgeCounter } from './model';

import './PledgeCounter.scss';

const PledgeCounter: FC<IPledgeCounter> = ({ pledgeCounter }) => {
  const [number, setNumber] = useState(0);
  const [isSend, setIsSend] = useState(() => Boolean(getCookies(cookieName)));
  const [isDisabled, setIsDisabled] = useState(false);
  const countUpRef = useRef(null);

  const { api, beforeCounter, afterCounter, thankYouMessage, thankYouCTA, cta, disclaimer } =
    pledgeCounter;

  const config = apiConfig[api[0]];

  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 0,
    delay: 0,
    separator: ',',
    duration: 3,
  });

  const getStatistics = useCallback(async () => {
    try {
      const { data } = await axios.get(`${config.baseUrl}${config.getUrl}`);
      const count = (data?.statistics?.[0]?.NumOfPladges || data?.count) * config.factor;
      setNumber(count);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') console.error(err);
    }
  }, []);

  const handleClick = useCallback(async () => {
    setIsDisabled(true);
    try {
      await axios.post(`${config.baseUrl}${config.postUrl}`, config.payload, {
        headers: {
          Authorization: config.authorization,
          origin: config.origin,
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
      setIsSend(true);
      setNumber((prevNumber) => prevNumber + config.factor);
      setIsDisabled(false);
      setCookies(cookieName, true, '/', 365);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') console.error(err);
    }
  }, []);

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    update(number);
  }, [number]);

  return (
    <div className="pledge-counter">
      <p className="pledge-counter__quantity">
        {beforeCounter ? <span>{beforeCounter}</span> : null}
        <span ref={countUpRef} className="pledge-counter__number" />
        {afterCounter ? <span>{afterCounter}</span> : null}
      </p>

      {isSend ? (
        <div className="pledge-counter__thank-you">
          <DangerouslySetInnerHtml
            className="pledge-counter__thank-you-msg"
            html={thankYouMessage}
          />
          <DangerouslySetInnerHtml className="pledge-counter__thank-you-cta" html={thankYouCTA} />
        </div>
      ) : (
        <div className="pledge-counter__form">
          <Button
            classes="pledge-counter__button"
            ariaLabel={cta[0].properties.ariaLabel}
            variant="primary"
            clickHandler={handleClick}
            disabled={isDisabled}
          >
            {cta[0].properties.link[0].name}
          </Button>
          <DangerouslySetInnerHtml className="pledge-counter__disclaimer" html={disclaimer} />
        </div>
      )}
    </div>
  );
};

export default PledgeCounter;
