import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DangerouslySetInnerHtml } from 'layout';
import Section from '../Section';

import { TTitleTextProps } from './models';
import './TitleText.scss';

const TitleText: FC<TTitleTextProps> = ({ data }) => (
  <div data-testid="TitleText" className="title-text-section">
    <Section name="sources" color={{ label: 'transparent' }}>
      <DangerouslySetInnerHtml
        className="title-text-section__title"
        element="h2"
        html={data.title}
      />
      <DangerouslySetInnerHtml
        className="title-text-section__description"
        html={data.description}
      />
    </Section>
  </div>
);

export default TitleText;

export const query = graphql`
  fragment FragmentTitleText on TTitleText {
    properties {
      title
      description
    }
    structure
  }
`;
