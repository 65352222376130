import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { Container, DangerouslySetInnerHtml } from 'layout';
import Icon from 'common/IconCustom';
import PledgeCounter from 'common/PledgeCounter';

import { IDivideTextSectionProps } from './models';

import './DivideTextSection.scss';

const DivideTextSection: FC<IDivideTextSectionProps> = ({ divideTextSection }) => {
  const {
    icon,
    leftTextSection,
    middleTextSection,
    rightTextSection,
    pledgeCounter,
    sectionTheme,
  } = divideTextSection[0].properties;

  return (
    <div
      data-testid="DivideTextSection"
      className={classnames('divide-text-section', {
        [`divide-text-section--${sectionTheme}`]: sectionTheme,
      })}
    >
      <Container className="divide-text-section__wrapper">
        <div className="divide-text-section__left-text-section--holder">
          <div className="divide-text-section__left-text-section">
            <Icon classes="left-section__icon" icon={icon[0]} />
            <p className="left-section__text">{leftTextSection}</p>
          </div>
        </div>
        <DangerouslySetInnerHtml
          className="divide-text-section__middle-text-section"
          html={middleTextSection}
        />
        <div className="divide-text-section__right-section">
          {rightTextSection ? (
            <p className="divide-text-section__right-section-text">{rightTextSection}</p>
          ) : null}
          {pledgeCounter?.[0]?.properties ? (
            <PledgeCounter pledgeCounter={pledgeCounter[0].properties} />
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentDivideTextSection on TDivideTextSection {
    properties {
      icon
      leftTextSection
      middleTextSection
      rightTextSection
      pledgeCounter {
        properties {
          api
          beforeCounter
          afterCounter
          cta {
            properties {
              link {
                name
              }
              ariaLabel
            }
          }
          disclaimer
          thankYouMessage
          thankYouCTA
        }
      }
      sectionTheme
    }
  }
`;

export default DivideTextSection;
