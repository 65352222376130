import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import Icon from 'common/IconCustom';

import { ISaveWaterPageCard } from './models';
import './SaveWaterPageCard.scss';

const SaveWaterPageCard: FC<ISaveWaterPageCard> = ({ data }) => {
  const { title, image, icon, link, iconSize } = data.properties;

  return (
    <Link
      to={link[0].url}
      data-testid="SaveWaterPageCard"
      className="save-water-page-card__wrapper"
    >
      <div className="save-water-page-card__text-wrapper">
        <div className="save-water-page-card__title">{title}</div>
        <Link to={link[0].url} className="save-water-page-card__link">
          {link[0].name}
        </Link>
      </div>
      <GatsbyImage
        image={image[0].properties.image}
        alt={image[0].properties.imageAlt}
        className="save-water-page-card__image"
        objectFit="contain"
      />
      <div className="save-water-page-card__icon">
        <Icon
          classes={classNames('icon-card', {
            [`icon-card--${iconSize?.[0]}`]: iconSize?.[0],
          })}
          icon={icon[0]}
        />
      </div>
    </Link>
  );
};

export const query = graphql`
  fragment FragmentSaveWaterPageCard on TPageCards {
    properties {
      icon
      iconSize
      title
      link {
        name
        target
        url
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default SaveWaterPageCard;
