const settings = {
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        className: 'center',
        centerMode: true,
        centerPadding: '15%',
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 650,
      settings: {
        className: 'center',
        centerMode: true,
        centerPadding: '5%',
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

export default settings;
