import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import { Container, DangerouslySetInnerHtml } from 'layout';

import { IMissionBlockProps } from './models';
import './MissionBlock.scss';

const MissionBlock: FC<IMissionBlockProps> = ({ missionBlock }) => {
  const { topTitle, mainTitle, description, backgroundImage, subImage1, subImage2, mainImage } =
    missionBlock[0].properties;

  return (
    <div data-testid="MissionBlock" className="mission-block__wrapper">
      <GatsbyImage
        image={backgroundImage[0].properties.image}
        alt={backgroundImage[0].properties.imageAlt}
        className="mission-block__bg-image"
      />
      <Container className="mission-block__container">
        <h5 className="mission-block__top-title">{topTitle}</h5>
        <DangerouslySetInnerHtml html={mainTitle} className="mission-block__main-title" />
        <GatsbyImage
          image={mainImage[0].properties.image}
          alt={mainImage[0].properties.imageAlt}
          className="mission-block__main-image"
        />
        {subImage1?.[0] && subImage2?.[0] ? (
          <div className="mission-block__sub-image-wrapper">
            <GatsbyImage
              image={subImage1[0].properties.image}
              alt={subImage1[0].properties.imageAlt}
              className="mission-block__sub-image-1"
            />
            <GatsbyImage
              image={subImage2[0].properties.image}
              alt={subImage2[0].properties.imageAlt}
              className="mission-block__sub-image-2"
            />
          </div>
        ) : null}
        <DangerouslySetInnerHtml html={description} className="mission-block__description" />
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentMissionBlock on TMissionBlock {
    properties {
      description
      mainTitle
      topTitle
      backgroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      subImage1 {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      subImage2 {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mainImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default MissionBlock;
